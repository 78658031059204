import React, { useEffect } from 'react';
import { ColDef, DataGrid, RowModel } from '@material-ui/data-grid';
import CloudDownload from '@material-ui/icons/CloudDownload';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';

import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import Button from 'components/CustomButtons/Button';
import {
  getStudentSpeakingProgressAsync,
  getWhatsappStatusAsync,
  isWhatsappReadySelector,
  sendWhatsappMessageAsync,
  studentSpeakingVideoTasksSelector,
  whatsappMessageReqSelector,
} from '../slices/adminSlice';

import styles from 'assets/jss/lms/features/questions';
import { makeStyles, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { createExcelSheet, downloadStudentReport } from 'features/helpers';
import { IconButton, Tooltip } from '@mui/material';

const useStyles = makeStyles(styles);

interface Props {}
const StudentSpeakingVideosManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const { studentId } = useParams<{ studentId: string }>();
  const studentTasks = useSelector(studentSpeakingVideoTasksSelector)?.speakingVideoTasks;
  const studentTasksReq = useSelector(studentSpeakingVideoTasksSelector).req;

  const studentName = localStorage.getItem('chosenStudentForSpeakingVideosTasks') || 'No Name';

  const [taskNameFilter, setTaskNameFilter] = React.useState<string>('');
  const [startDateFilter, setStartDateFilter] = React.useState<Date | undefined>(undefined);
  const [endDateFilter, setEndDateFilter] = React.useState<Date | undefined>(undefined);

  const isWhatsappReady = useSelector(isWhatsappReadySelector);
  const whatsappMessageReq = useSelector(whatsappMessageReqSelector);

  const getRows = (): any[] => {
    if (!studentTasks) {
      return [];
    } else {
      const rows: RowModel[] = studentTasks.map((progress, index) => ({
        id: index.toString(),
        ...progress,
      }));
      return rows;
    }
  };

  const columns: ColDef[] = [
    // { field: 'id', headerName: 'Id', width: 90 },
    { field: 'videoName', headerName: 'Video Name', width: 600 },
    {
      field: 'assignedDate',
      headerName: 'Assigned Date',
      valueFormatter: v => new Date(v.value as string).toUTCString().substring(0, 25),
      width: 300,
    },
    {
      field: 'submissionDate',
      headerName: 'Submission Date',
      valueFormatter: v => (v.value ? new Date(v.value as string).toUTCString().substring(0, 25) : 'Not Submitted'),
      width: 300,
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 150,
      valueFormatter: v => (v.value ? `${v.value}%` : '0 %'),

      // renderCell: params => (
      //   <div style={{ color: parseFloat(params?.value?.toString() || '0') < 60 ? 'red' : 'green' }}>
      //     {params.row.percentage}
      //   </div>
      // ),
    },
    // {
    //   field: '',
    //   headerName: 'Status',
    //   width: 150,
    //   renderCell: params => (
    //     <div
    //       style={{
    //         backgroundColor:
    //           parseFloat(params.row.percentage) > 90
    //             ? 'blue'
    //             : parseFloat(params.row.percentage) > 75
    //             ? 'green'
    //             : parseFloat(params.row.percentage) >= 60
    //             ? 'yellow'
    //             : parseFloat(params.row.percentage) >= 50
    //             ? 'orange'
    //             : 'red',
    //         width: '100%',
    //         height: '100%',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         textAlign: 'center',
    //         borderRadius: '4px',
    //       }}
    //     >
    //       <span
    //         style={{
    //           color: parseFloat(params.row.percentage) > 75 ? 'white' : 'black',
    //           fontWeight: 'bold',
    //         }}
    //       >
    //         {parseFloat(params.row.percentage) > 90
    //           ? 'Excellent'
    //           : parseFloat(params.row.percentage) > 75
    //           ? 'Very Good'
    //           : parseFloat(params.row.percentage) >= 60
    //           ? 'Good'
    //           : parseFloat(params.row.percentage) >= 50
    //           ? 'pass'
    //           : 'Bad'}
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  useReqListener({
    status: studentTasksReq.status,
    successMessage: 'Successfully fetched student tasks',
    loadingMessage: 'Fetching student tasks',
    errorMessage: studentTasksReq.error,
  });

  useReqListener({
    status: whatsappMessageReq.status,
    successMessage: 'Message Sent Successfully',
    loadingMessage: 'Sending message ...',
    errorMessage: whatsappMessageReq.error,
  });

  React.useEffect(() => {
    if (studentId && dispatch && studentTasksReq.status === 'idle') {
      dispatch(getStudentSpeakingProgressAsync({ studentId, token }));
    }
  }, [studentId, dispatch, studentTasksReq.status, token]);

  const handleDownloadReport = () => {
    const newRows = filteredRows.map((r, index) => ({
      // index: index,
      videoName: r.videoName,
      assignedDate: new Date(r.assignedDate as string).toUTCString().substring(0, 25),

      submissionDate: r.submissionDate
        ? new Date(r.submissionDate as string).toUTCString().substring(0, 25)
        : 'Not Submitted',

      percentage: r.percentage ? `${r.percentage}%` : '0 %',
      // Status:
      //   parseFloat(r.percentage) > 90
      //     ? 'Excellent'
      //     : parseFloat(r.percentage) >= 75
      //     ? 'Very Good'
      //     : parseFloat(r.percentage) >= 60
      //     ? 'Good'
      //     : parseFloat(r.percentage) >= 50
      //     ? 'Pass'
      //     : 'Bad',
    }));
    const [firstName, secondName, lastName] = studentName.split(' ');
    const finalName = lastName ? `${firstName}_${secondName}_${lastName}` : `${firstName}_${secondName}`;
    downloadStudentReport(newRows, columns, finalName);
  };

  const onSendWhatsappMessage = () => {
    const [firstName, secondName, lastName] = studentName.split(' ');
    const finalName = lastName ? `${firstName}_${secondName}_${lastName}` : `${firstName}_${secondName}`;

    const message = `Dear Parent,
We hope this message finds you well. We would like to inform you about the speaking video progress of the student, *${studentName}*.
    
We appreciate your continued support in the student's education. If you have any questions or concerns, please don't hesitate to contact us.
    
Best regards,
Dr. Fady ElFahdy`;

    if (isWhatsappReady)
      dispatch(
        sendWhatsappMessageAsync({
          studentId,
          message,
          // phone: '01205301234',
          file: handleSendReport(),
          studentName: finalName,
          token,
        }),
      );
  };

  useEffect(() => {
    dispatch(getWhatsappStatusAsync({ token }));
  }, [isWhatsappReady]);

  const handleSendReport = () => {
    const newRows = filteredRows.map(({ ['id']: _, ...r }) => ({
      ...r,
      assignedDate: new Date(r.assignedDate as string).toUTCString().substring(0, 25),
      submissionDate: r.submissionDate
        ? new Date(r.submissionDate as string).toUTCString().substring(0, 25)
        : 'Not Submitted',
      percentage: r.percentage ? `${r.percentage}%` : '0 %',
    }));
    const [firstName, secondName, lastName] = studentName.split(' ');
    const finalName = lastName ? `${firstName}_${secondName}_${lastName}` : `${firstName}_${secondName}`;
    const excelFile = createExcelSheet(newRows, columns, finalName);
    const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    return blob as File;
  };

  let filteredRows = getRows();

  if (taskNameFilter && taskNameFilter !== '') {
    filteredRows = filteredRows.filter(q => q && q.videoName?.toLowerCase().includes(taskNameFilter.toLowerCase()));
  }

  if (startDateFilter) {
    filteredRows = filteredRows.filter(
      q => q && q.assignedDate && new Date(q.assignedDate) > new Date(startDateFilter),
    );
  }

  if (endDateFilter) {
    filteredRows = filteredRows.filter(q => q && q.assignedDate && new Date(q.assignedDate) < new Date(endDateFilter));
  }

  return (
    <div
      style={{ height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
    >
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        style={{
          marginTop: '20px',
        }}
      >
        {true && <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}> {studentName}'s Progress</h6>}
      </CardHeader>

      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex' }}>
          <TextField
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="Task Name Filter"
            onChange={e => setTaskNameFilter(e.target.value)}
          />

          <TextField
            id="date"
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="From"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setStartDateFilter(new Date(e.target.value)) : setStartDateFilter(undefined)
            }
          />
          <TextField
            id="date"
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="To"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setEndDateFilter(new Date(e.target.value)) : setEndDateFilter(undefined)
            }
          />
        </div>

        <div style={{ display: 'flex', marginTop: '10px' }}>
          <Tooltip title="Download Report">
            <IconButton color="primary" onClick={handleDownloadReport}>
              <CloudDownload fontSize="large" />
            </IconButton>
          </Tooltip>

          <Tooltip title={isWhatsappReady ? 'Message Parent' : 'Connect First'}>
            <span>
              <IconButton color="success" onClick={onSendWhatsappMessage} disabled={!isWhatsappReady}>
                <WhatsappIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Email Parent">
            <IconButton color="error">
              <MailIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <DataGrid
        rows={filteredRows}
        pagination
        columns={columns}
        density="comfortable"
        autoHeight
        loading={studentTasksReq.status === 'loading'}
      />
    </div>
  );
};

export default StudentSpeakingVideosManagement;
